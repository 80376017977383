.main {
	width: 100%;
	height: 140px;
	display: flex;
	align-items: flex-start;

	justify-content: center;
}

.content {
	width: 85%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 40px;
}

.content p,
.content a {
	font-size: 14px;
	text-align: center;
	font-family: var(--consolas-font);
}

.logo_section {
	display: flex;
	gap: 25px;
}

.main a:hover {
	opacity: 0.7;
}

@media screen and (max-width: 800px) {
	.content {
		margin-top: 25px;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}
}
