.main {
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
}

.main p {
	font-size: clamp(1rem, 3vw + 0.5rem, 1.8rem);
	color: #535353;
	font-family: "Consolas";
}

.main h1 {
	font-size: clamp(2.25rem, 5vw + 1rem, 5.625rem);
	font-weight: bold;
	font-family: "Consolas";
}

.main h3 {
	font-size: clamp(1.3rem, 3vw + 0.2rem, 2.5rem);
	font-family: "Consolas";
	font-weight: 400;
}

.main h3 span {
	color: #535353;
}

.main a {
	font-size: 16px;
	border: 1px solid white;
	width: fit-content;
	padding: 14px 38px;
	margin-top: 45px;
	font-family: var(--roboto-font);
}

.main a:hover {
	opacity: 0.6;
	color: red;
	border-color: red;
}

.bg_img {
	/*font-size: 517px;*/
	font-size: clamp(90px, 27vw + 25px, 517px);
	background-size: cover;
	z-index: -3;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	opacity: 0.4;
	white-space: nowrap;
	position: absolute;
	left: 41vw;
	top: 23.5vh;
	background-image: url("../../assets/images/computer-bg.webp");
}

@media screen and (max-width: 800px) {
	.bg_img {
		top: 40vh;
	}

	.main h1,
	.main h3 {
		margin-top: 10px;
	}
}
