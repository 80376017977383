.main {
	gap: 25px;
	align-items: center;
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	overflow-y: hidden;
	justify-content: flex-start;
	flex-wrap: nowrap;
	margin-top: 40px;
	width: 94%;
	height: 100%;
	padding: 2%;
	max-height: 620px;
	animation: visible 0.5s ease-in-out 1, scaleUp 0.4s ease-out 1;
}

@media screen and (max-height: 500px) {
	.main {
		margin-top: 10px;
		max-height: 500px;
	}
}

@media screen and (max-width: 800px) {
	.main {
		margin-top: 10px;
		max-height: 590px;
		width: 94%;
	}
}
