@font-face {
	font-family: "Consolas";
	font-style: normal;
	font-weight: normal;
	src: url(./assets/fonts/CONSOLA.TTF) format("truetype");
}

@font-face {
	font-family: "Consolas";
	font-style: normal;
	font-weight: bold;
	src: url(./assets/fonts/Consolas-Bold.woff) format("woff");
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

:root {
	--roboto-font: "Roboto", sans-serif;
	--roboto-condensed-font: "Roboto Consensed", sans-serif;
	--consolas-font: "Consolas", sans-serif;
}

.bold {
	font-weight: bold;
}
