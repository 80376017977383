.navBar {
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 6;
	position: fixed;

	height: 65px;
	overflow: hidden;
}

.navBar_content {
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	overflow: hidden;
	z-index: 7;
	height: 65px;
}

.navBar p {
	z-index: 9;
}

.navTitle {
	font-size: 16px;
	z-index: 99;
	font-family: var(--roboto-condensed-font) !important;
}

.navTitle span {
	font-family: var(--roboto-condensed-font) !important;
}

.navbar_nav_row {
	display: flex;
	gap: 21px;
	align-items: center;
	z-index: 10;
}

.navbar_nav_row a {
	font-size: 14px;
	font-weight: bold;
	font-family: var(--roboto-font) I !important;
}

.navbar_nav_row a:hover {
	opacity: 0.7;
}

.navbar_nav_row svg:hover path {
	opacity: 0.7;
}

.blurred_bg {
	width: 110%;
	height: 65px;
	top: 0;
	left: 0;
	z-index: -1;
	background-color: rgba(0, 0, 0, 0.21);
	top: 0;
	margin-top: -2px;
	-webkit-filter: blur(6px);
	backdrop-filter: blur(12px);
	filter: blur(0px);
	z-index: 4;
}

@media screen and (max-width: 700px) {
	.navbar_nav_row {
		display: none;
	}

	.navBar {
		justify-content: center;
	}

	.navBar_content {
		justify-content: center;
	}
}
