.main {
	width: 100%;
	height: 100vh;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.heading {
	font-size: 20px;
	color: #ff0000;
	font-weight: bold;
	margin-top: 80px;
	font-family: var(--roboto-condensed-font) !important;
}

.main p {
	font-size: 48px;
	color: black;
	font-weight: bold;
	margin-top: 8px;
	font-family: var(--roboto-condensed-font);
}

@media screen and (max-height: 500px) {
	.main {
		height: 140vh !important;
	}
}

@media screen and (max-width: 800px) {
	.main p {
		font-size: 40px;
	}

	.main {
		height: 105vh;
	}
}
