/*  Basic Setup */
html {
	overflow: scroll;
	overflow-x: hidden;
	background-color: #000000;
	scroll-behavior: smooth !important;
}

::-webkit-scrollbar {
	width: 0; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
	background: #ff0000;
}
img {
	width: 100%;
	height: 100%;
}

span:hover {
	cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
button {
	font-family: Consolas, "Courier New", Courier, monospace;
	font-size: 33px;
	color: #ffff;
	font-weight: normal;
	margin: 0px;
	text-decoration: none;
}

.page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 1915px;
	margin: 0 auto;
}
